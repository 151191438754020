<!-- client/src/views/SearchResults.vue -->
<template>
  <div class="search-results">
    <HeaderPanel />
    <h2>Arama Sonuçları: "{{ $route.query.q }}"</h2>
    <VideoList :videos="videos" />
  </div>
</template>

<script>
import axios from 'axios';
import VideoList from '@/components/VideoList.vue';
import HeaderPanel from '@/components/HeaderPanel.vue';

export default {
  components: {
    VideoList,
    HeaderPanel,
  },
  data() {
    return {
      videos: [],
    };
  },
  watch: {
    '$route.query.q': 'fetchResults',
  },
  created() {
    this.fetchResults();
  },
  methods: {
    fetchResults() {
      const query = this.$route.query.q;
      axios.get(window.BASE_URL+`/api/search?q=${query}`)
        .then(response => {
          this.videos = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
  },
};
</script>

<style scoped>
/* Stil tanımları */
</style>