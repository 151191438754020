<!-- client/src/components/VideoList.vue -->
<template>
    <div class="video-list">
      <div
        v-for="video in videos"
        :key="video.id.videoId"
        class="video-item"
        @click="selectVideo(video)"
      >
        <img :src="video.thumbnail" alt="Video Thumbnail" />
        <h4>{{ video.title }}</h4>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['videos'],
    methods: {
      selectVideo(video) {
        this.$router.push({ name: 'VideoDetail', params: { id: video.id } });
      },
    },
  };
  </script>
  
  <style scoped>
    .video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-items: center;
    }
    h4 {
      margin: 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .video-item {
      width: 240px;
      margin: 10px;
      cursor: pointer;
    }
    .video-item img {
      width: 100%;
      height: auto;
    }

  </style>
  