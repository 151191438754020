<!-- client/src/components/VideoPlayer.vue -->
<template>
  <div class="video-player" v-if="videoId">
    <div class="video-container">
      <div id="youtube-player"></div>
    </div>
    <div class="controls">
      <div class="buttons">
        <button @click="togglePlayPause">
          <span class="material-symbols-outlined" v-if="isPlaying">pause_circle</span>
          <span class="material-symbols-outlined" v-else>
            play_circle
          </span>
        </button>
      </div>
      <input type="range" min="0" :max="duration" step="1" v-model="currentTime" @input="seekVideo" />
      <div class="time-info">
        <span>{{ formatTime(currentTime) }}</span> / <span>{{ formatTime(duration) }}</span>
      </div>
    </div>
    <div class="video-info-container">
      <h2>{{ video.snippet.title }}</h2>

      <div class="video-info">
        <img :src="channelThumbnail" alt="Channel Thumbnail" />
        <h3>{{ channelName }}</h3>
      </div>
    </div>
  </div>
  <div v-else>
    <p>Video yükleniyor...</p>
  </div>
</template>

<script>

/* global YT */

export default {
  props: ['video', 'channels'],
  data() {
    return {
      player: null,
      duration: 0,
      currentTime: 0,
      isPlayerReady: false,
      isPlaying: false,
      videoEnded: false,
    };
  },
  computed: {
    videoId() {
      if (this.video && this.video.id) {
        if (typeof this.video.id === 'object') {
          return this.video.id.videoId;
        }
        return this.video.id;
      }
      return null;
    },
    iframeSrc() {
      if (this.videoId) {
        return `https://www.youtube.com/embed/${this.videoId}?rel=0&modestbranding=1&controls=0&iv_load_policy=3&enablejsapi=1`;
      }
      return '';
    },
    channelName() {
      return this.video ? this.video.snippet.channelTitle : '';
    },
    channelThumbnail() {
      if (this.video) {
        const channel = this.channels.find(
          (c) => c.id === this.video.snippet.channelId
        );
        return channel ? channel.thumbnail : '';
      }
      return '';
    },
  },
  watch: {
    video(newVideo) {
      if (newVideo) {
        this.loadYouTubeAPI();
      }
    },
  },
  mounted() {
    if (this.video) {
      this.loadYouTubeAPI();
    }
  },
  beforeUnmount() {
    if (this.player) {
      this.player.destroy();
      this.player = null;
    }
    // API betiğini kaldırmak isterseniz ekleyebilirsiniz
    const tag = document.getElementById('youtube-api');
    if (tag) {
      tag.remove();
    }
  },
  methods: {
    loadYouTubeAPI() {

      if (!window.YT) {
        console.log('Youtube Yukleniyor...');
        // YouTube API henüz yüklenmemişse yükleyin
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        tag.id = 'youtube-api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        // API yüklemesi tamamlandığında `onYouTubeIframeAPIReady` fonksiyonu çağrılır
        window.onYouTubeIframeAPIReady = this.createPlayer;
      } else {
        console.log('Youtube Hazir...');
        // API zaten yüklüyse oynatıcıyı oluşturun
        this.createPlayer();
      }
    },
    createPlayer() {
      if (!window.YT || !YT.Player) {
        console.error('YouTube API yüklenemedi.');
        return;
      }

      if (document.getElementById('youtube-player')) {

        if (this.player) {
          this.player.destroy();
          this.player = null;
          this.isPlayerReady = false;
        }

        this.player = new YT.Player('youtube-player', {
          height: '390',
          width: '640',
          videoId: this.videoId,
          playerVars: {
            autoplay: 0,
            rel: 0,
            modestbranding: 1,
            controls: 1, // YouTube kontrollerini gizler
            iv_load_policy: 3, // Anotasyonları gizler
            enablejsapi: 1, // JS API'yi etkinleştirir
          },
          events: {
            onReady: this.onPlayerReady,
            onStateChange: this.onPlayerStateChange,
          },

        });
      } else {
        setTimeout(this.createPlayer, 500);
      }

    },
    onPlayerReady(event) {
      this.isPlayerReady = true;
      this.duration = event.target.getDuration();
      this.updateTime();
    },
    onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.PLAYING && !this.duration) {
        this.duration = event.target.getDuration();
      }
      if (event.data === YT.PlayerState.PLAYING) {
        this.isPlaying = true;
        this.videoEnded = false;
      } else if (event.data === YT.PlayerState.PAUSED) {
        this.isPlaying = false;
      }

      if (event.data === YT.PlayerState.ENDED) {
        this.videoEnded = true;
      }
    },
    updateTime() {
      if (this.player && this.isPlayerReady) {
        this.currentTime = this.player.getCurrentTime();
        this.duration = this.player.getDuration();
        requestAnimationFrame(this.updateTime);
      }
    },
    seekVideo() {
      if (this.player && this.isPlayerReady) {
        this.player.seekTo(this.currentTime, true);
      }
    },
    formatTime(seconds) {
      const secNum = parseInt(seconds, 10);
      let hours = Math.floor(secNum / 3600);
      let minutes = Math.floor((secNum - hours * 3600) / 60);
      let secs = secNum - hours * 3600 - minutes * 60;

      if (hours < 10) hours = '0' + hours;
      if (minutes < 10) minutes = '0' + minutes;
      if (secs < 10) secs = '0' + secs;
      if (hours > 0) {
        return hours + ':' + minutes + ':' + secs;
      }
      return minutes + ':' + secs;
    },
    togglePlayPause() {
      if (this.player && this.isPlayerReady) {
        const playerState = this.player.getPlayerState();
        if (playerState === YT.PlayerState.PLAYING) {
          this.player.pauseVideo();
        } else {
          this.player.playVideo();
        }
      }
    },
  },
};
</script>

<style>
.video-player {
  width: 800px;
  margin: 0 auto;
}

.video-player iframe {
  width: 100%;
}

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 oranı */
  height: 0;
  overflow: hidden;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.video-info {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.video-info img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.controls {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.controls input[type='range'] {
  width: 100%;
  -webkit-appearance: none;
  background: #ddd;
  height: 5px;
  border-radius: 5px;
  outline: none;
}

.controls button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
}

.controls button span {
  font-size: 35px;
}

.controls input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 15px;
  height: 15px;
  background: #555;
  cursor: pointer;
  border-radius: 50%;
}

.controls input[type='range']::-moz-range-thumb {
  width: 15px;
  height: 15px;
  background: #555;
  cursor: pointer;
  border-radius: 50%;
}

.time-info {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  margin-top: 5px;
}

div#youtube-hidden {
  position: absolute;
  bottom: 0;
  left: 0;
  top: 0;
  right: 0;
  z-index: 5;
  background: #f0f8ff99;
  width: 100%;
}

div#youtube-hidden span {

  font-size: 50px;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 25px);
  left: calc(50% - 25px);

}

#youtube-hidden-top {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  height: 80px;
}

#youtube-hidden-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
  top: 0;
  width: 100%;
}

div#youtube-hidden span {
  font-size: 50px;
  cursor: pointer;
}
</style>
