// client/src/main.js
import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createI18n } from 'vue-i18n';

const app = createApp(App);


import en from './locales/en.json';
import tr from './locales/tr.json';

const i18n = createI18n({
  locale: 'en', // Varsayılan dil
  fallbackLocale: 'en', // Desteğe sahip olmayan diller için yedek dil
  messages: {
    en,
    tr,
  },
});

app.use(router);
app.use(i18n);


  
app.mount('#app');
