<!-- client/src/views/ChannelDetail.vue -->
<template>
    <HeaderPanel :channels="channels"/>
    <div class="channel-detail">
      <div class="channel-info-header" v-if="channel">
      <h2 >{{ channel.name }}</h2>
      <img :src="channel.thumbnail" alt="Channel Thumbnail" class="channel-thumbnail" />
      </div>
      <h3>Videolar</h3>
      <VideoList :videos="videos" :channels="channels"/>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import VideoList from '@/components/VideoList.vue';
  import HeaderPanel from '@/components/HeaderPanel.vue';

  export default {
    props: ['channels'],
    components: {
      VideoList,
      HeaderPanel
    },
    data() {
      return {
        channel: null,
        videos: [],
      };
    },

    created() {
      const channelId = this.$route.params.id;
      this.channel = this.channels.find(c => c.id === channelId);
      axios.get(window.BASE_URL+`/api/channel/${channelId}`)
        .then(response => {
          this.videos = response.data;
        })
        .catch(error => {
          console.error(error);
        });
    },
    watch: {
      channels() {
        const channelId = this.$route.params.id;
        this.channel = this.channels.find(c => c.id === channelId);
      }
    }
  };
  </script>
  
  <style scoped>
  .channel-thumbnail {
    height: 42px;
    border-radius: 10px;
  }
  h3 {
    text-align: center;
  }
  .channel-info-header {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 20px;
  }
  </style>
  