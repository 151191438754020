// client/src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '@/views/HomePage.vue';
import VideoDetail from '@/views/VideoDetail.vue';
import ChannelDetail from '@/views/ChannelDetail.vue';
import SearchResults from '@/views/SearchResults.vue';

const routes = [
  { path: '/', name: 'Home', component: HomePage,  },
  { path: '/video/:id', name: 'VideoDetail', component: VideoDetail ,props: true},
  { path: '/channel/:id', name: 'ChannelDetail', component: ChannelDetail },
  { path: '/search', name: 'SearchResults', component: SearchResults },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
