<!-- client/src/views/Home.vue -->
<template>
  <div class="home">
    <HeaderPanel :channels="channels" />
    <div class="video-list-container">

      <div class="tab-panel">
        <div :class="{ active: selectedTab === 'videos' }" @click="selectTab('videos')"><span
            class="material-symbols-outlined">toys_fan</span> Videos</div>
        <div :class="{ active: selectedTab === 'music' }" @click="selectTab('music')"><span
            class="material-symbols-outlined">
            headphones
          </span> Music</div>
        <div :class="{ active: selectedTab === 'channels' }" @click="selectTab('channels')"><span
            class="material-symbols-outlined">
            playlist_play
          </span> Channels</div>
      </div>

      <ChannelList id="channel-list" :channels="channels" v-if="selectedTab === 'channels'" />
      <div class="latest-videos" v-if="selectedTab === 'videos' || selectedTab === 'music'">
        <h2 class="title-header">En Son Videolar</h2>
        <VideoList :videos="videos" :channels="channels" />
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VideoList from '@/components/VideoList.vue';
import ChannelList from '@/components/ChannelList.vue';
import HeaderPanel from '@/components/HeaderPanel.vue';

export default {
  name: 'HomePage',
  props: ['channels'],
  components: {
    VideoList,
    ChannelList,
    HeaderPanel,
  },
  data() {
    return {
      videos: [],
      selectedTab: 'videos',
    };
  },
  methods: {
    selectTab(tab) {
      this.selectedTab = tab;
    },
  },
  watch: {
    selectedTab() {
      if (this.selectedTab === 'videos') {
        axios
          .get(window.BASE_URL+'/api/videos')
          .then((response) => {
            this.videos = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      } else if (this.selectedTab === 'music') {
        axios
          .get(window.BASE_URL+'/api/videos?category=music')
          .then((response) => {
            this.videos = response.data;
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  },
  created() {
    axios
      .get(window.BASE_URL+'/api/videos')
      .then((response) => {
        this.videos = response.data;
      })
      .catch((error) => {
        console.error(error);
      });
  },
};
</script>

<style scoped>
.title-header {
  text-align: center;
}
.latest-videos {
  margin-left: 15px;
}

.tab-panel {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  margin: 0 50px;
  gap: 40px;
}

.tab-panel div {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  padding: 5px 10px;
}

.active {
  background: #fb636373;
  border-radius: 8px;
}
</style>
