<!-- client/src/components/SearchBar.vue -->
<template>
    <div class="search-bar">
      <input
        type="text"
        v-model="query"
        @keyup.enter="search"
        placeholder="Videoları ara..."
      />
      <button @click="search">Ara</button>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        query: '',
      };
    },
    methods: {
      search() {
        this.$router.push({ name: 'SearchResults', query: { q: this.query } });
      },
    },
  };
  </script>
  
  <style scoped>
  /* Stil tanımları */
  </style>
  