<!-- client/src/views/VideoDetail.vue -->
<template>
  <HeaderPanel :channels="channels" />
  <div class="video-detail">
    <VideoPlayer :video="video" :channels="channels" :id="id" />
    <div class="related-videos-container">
      <h2>Benzer Videolar</h2>
      <VideoListMini :videos="relatedVideos" />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import VideoPlayer from '@/components/VideoPlayer.vue';
import VideoListMini from '@/components/VideoListMini.vue';
import HeaderPanel from '@/components/HeaderPanel.vue';

export default {
  name: 'VideoDetail',
  props: ['channels', 'id'],
  components: {
    VideoPlayer,
    VideoListMini,
    HeaderPanel,
  },
  data() {
    return {
      video: null,
      relatedVideos: [],
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.fetchVideo();
    setTimeout(() => {
        console.log(this.id);
    }, 1000);
  },
  methods: {
    async fetchVideo() {
      if (!this.id) {
        this.error = 'Video ID bulunamadı.';
        return;
      }

      this.isLoading = true;
      this.error = null;

      try {
        // Video verisini çekme
        const videoResponse = await axios.get(`${window.BASE_URL}/api/video/${this.id}`);
        this.video = videoResponse.data;
        console.log('Video verisi yüklendi:', this.video);
      } catch (error) {
        console.error('Video verisi yüklenirken hata oluştu:', error);
        this.error = 'Video verisi yüklenirken bir hata oluştu.';
      }

      try {
        // İlgili videoları çekme
        const relatedResponse = await axios.get(`${window.BASE_URL}/api/related/${this.id}`);
        this.relatedVideos = relatedResponse.data;
        console.log('İlgili videolar yüklendi:', this.relatedVideos);
      } catch (error) {
        console.error('İlgili videolar yüklenirken hata oluştu:', error);
        this.error = 'İlgili videolar yüklenirken bir hata oluştu.';
      }

      this.isLoading = false;
    },
  },
  watch: {
    id(newId, oldId) {
      if (newId !== oldId) {
        this.fetchVideo();
      }
    },
  },
};
</script>

<style scoped>
.video-detail {
  display: grid;
  grid-template-columns: 1fr 360px;
  gap: 20px;
}

h2 {
  margin-top: 0px;
}

@media screen and (max-width: 1200px) {
  .video-detail {
    grid-template-columns: 1fr;
  }
}

.error-message {
  color: red;
  text-align: center;
  margin-top: 20px;
}

.loading-message {
  text-align: center;
  margin-top: 20px;
}
</style>
