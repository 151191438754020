<!-- client/src/components/ChannelList.vue -->
<template>
  <div class="channel-list">
    <h2 class="title-header-channel">Kanallar</h2>
    <ul>
      <li v-for="channel in channels" :key="channel.id" class="channel-item">
        <img :src="channel.thumbnail" alt="Channel Thumbnail" class="channel-thumbnail"
          @click="selectChannel(channel)" />
        <h4 @click="selectChannel(channel)">{{ channel.name }}</h4>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  props: ['channels'],
  data() {
    return {

    };
  },
  methods: {
    selectChannel(channel) {
      this.$router.push({ name: 'ChannelDetail', params: { id: channel.id } });
    },
  },
};
</script>

<style scoped>
.channel-list {
  margin-left: 15px;
}

.channel-thumbnail {
  width: 72px;
  height: 72px;
  object-fit: cover;
  border-radius: 50%;
}

.channel-item {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.title-header-channel {
  text-align: center;
}

ul {
  list-style-type: none;
  padding: 0;
  width: fit-content;
  margin: auto;
}
ul li {
  margin-bottom: 20px;
}
</style>