<!-- client/src/components/VideoList.vue -->
<template>
    <div class="video-list">
      <div
        v-for="video in videos"
        :key="video.id.videoId"
        class="video-item"
        @click="selectVideo(video)"
      >
        <img :src="video.thumbnail" alt="Video Thumbnail" />
        <h4 v-html="video.title"> </h4>
        <div class="video-info-with-channel">
          <img :src="getChannelThumbnail(video)" alt="Channel Thumbnail" class="channel-thumbnail" /> <span>  {{ getChannelName(video) }}</span>
        </div>
      </div>
    </div>
  </template>
  
  <script>

  export default {
    props: ['videos', 'channels'],
    methods: {
      selectVideo(video) {
        this.$router.push({ name: 'VideoDetail', params: { id: video.id } });
      },
      getChannelThumbnail(video) {
        const channel = this.channels.find(
          (c) => c.id === video.channel_id
        );
        return channel ? channel.thumbnail : '';
      },
      getChannelName(video) {
        const channel = this.channels.find(
          (c) => c.id === video.channel_id
        );
        return channel ? channel.name : '';
      },
    },
  };
  </script>
  
  <style scoped>
    .video-list {
      display: flex;
      flex-wrap: wrap;
      justify-content: start;
      align-items: center;
    }
    h4 {
      margin: 0;
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .video-item {
      width: 360px;
      margin: 10px;
      cursor: pointer;
      padding: 15px;
      border-radius: 15px;
    }
    .video-item img {
      width: 100%;
      height: auto;
      border-radius: 10px;
    }
    .video-info-with-channel {
      display: flex;
      align-items: center;
      gap: 10px;
      margin-top: 5px;
    }
    .channel-thumbnail {
      width: 36px!important;
      height: 36px!important;;
      object-fit: cover;
      border-radius: 50%;
    }
    .channel-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      gap: 10px;
    }

  </style>
  