<!-- client/src/App.vue -->
<template>
  <div id="app">
    <router-view :channels="channels" />
  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'App',
  data() {
    return {
      channels: [],
    };
  },
  created() {
    axios.get(window.BASE_URL + '/api/channels')
      .then(response => {
        this.channels = response.data;
      })
      .catch(error => {
        console.error(error);
      });
    window.addEventListener('urlChange', (event) => {
      this.controlToForward(event.detail);
    });
  },
  methods: {
    controlToForward(url) {
      console.log('validate url', url);
      // YouTube video ID'sini almak için regex
      const videoIdMatch = url.match(/[?&]v=([^&]+)/);
      if (videoIdMatch && videoIdMatch[1]) {
        const videoId = videoIdMatch[1];

        // Video ID'yi sunucuya gönder
        axios.post(`${window.BASE_URL}/api/validateVideo`, { videoId })
          .then(response => {
            if (response.data.status === true) {
              this.$router.push({ name: 'VideoDetail', params: { id: videoId } });
            } else {
              console.error('Video validation failed or video is not allowed.');
            }
          })
          .catch(error => {
            console.error('Error validating video:', error);
          });
      } else {
        console.error('No valid video ID found in the URL.');
      }
    }
  }
};
</script>


<style>
html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  background-color: #f0f0f0;
}
</style>
<style></style>